<template>
    <header class="page-title">
        <h1><i class="bi bi-people-fill"></i> 案件リスト</h1>
        <div>
            <router-link v-if="$store.getters['auth/canEditProject']" :to="{name: 'ProjectImport'}" class="btn btn-outline-primary me-3"><i class="bi bi-upload"></i> CSV一括登録</router-link>
            <router-link v-if="$store.getters['auth/canEditProject']" :to="{name: 'ProjectAdd'}" class="btn btn-outline-primary"><i class="bi bi-plus"></i> 新規登録</router-link>
        </div>
    </header>

    <inline-loader v-if="loading_count > 0"></inline-loader>
    <template v-else>
        <!-- 検索欄 -->
        <section class="section">
            <section class="section">
                <ul class="nav nav-tabs mb-3">
                    <li class="nav-item">
                        <button class="nav-link text-dark" :class="is_list ? 'active' : ''" @click="this.is_list = true;" type="button">リスト</button>
                    </li>
                    <li class="nav-item">
                        <button class="nav-link text-dark" :class="is_list ? '' : 'active'" @click="this.is_list = false" type="button">詳細検索</button>
                    </li>
                </ul>
                <div v-if="is_list === true">
                    <form @submit.prevent="search()" class="row align-items-end">
                        <div class="col-8">
                            <label class="form-label">リスト</label>
                            <form-select-search
                                v-model="search_list.condition_id"
                                :options="options_condition"
                                @selected="setListToCondition"
                            ></form-select-search>
                        </div>
                        <div class="col-8">
                            <label class="form-label">フリーワード</label>
                            <form-input
                                v-model="condition.keyword"
                            ></form-input>
                        </div>
                        <div class="col-3">
                            <button
                                type="submit"
                                :disabled="loading_count > 0"
                                class="btn btn-outline-primary"
                            >
                                <i class="bi bi-search"></i> 検索
                            </button>
                        </div>
                        <div class="col-6 text-start">
                            <button type="button" v-if="this.search_list.condition_id" @click.prevent="updateFixList()" class="btn btn-link">
                                <i class="bi bi-pin-fill"></i> {{ fixed_list_text }}
                            </button>
                        </div>
                        <div class="col-6 ms-auto text-end">
                            <router-link :to="{name: 'Condition'}" class="btn btn-outline-primary">リスト管理</router-link>
                        </div>
                    </form>
                </div>
                <div v-else>
                    <div class="text-start my-4">
                        <button v-if="show_search_form" type="button" @click="show_search_form = false" class="btn btn-outline-primary me-3">検索フォーム閉じる <i class="bi bi-chevron-up"></i></button>
                        <button v-else type="button" @click="show_search_form = true" class="btn btn-outline-primary me-3 mb-3">検索フォーム開く <i class="bi bi-chevron-down"></i></button>
                    </div>
                    <template v-if="show_search_form">
                        <form class="align-items-end" @submit.prevent="search()">
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">フリーワード</label>
                                    <form-input
                                        v-model="condition.keyword"
                                    ></form-input>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">事業部</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.department_operator"
                                            :options="StatusOperator.options()"
                                            @selected="updateDepartmentLinkedItems(true)"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.department_in"
                                            :options="options_department"
                                            class="custom-multiselect-tag"
                                            @selected="updateDepartmentLinkedItems(true)"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">反響対応者</label>
                                    <form-select-search
                                        v-model="condition.contact_user_id"
                                        :options="options_user"
                                    ></form-select-search>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">追客担当</label>
                                    <form-select-search
                                        v-model="condition.follow_user_id"
                                        :options="options_user"
                                    ></form-select-search>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">営業担当</label>
                                    <form-select-search
                                        v-model="condition.sales_user_id"
                                        :options="options_user"
                                    ></form-select-search>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">エリア別</label>
                                    <form-select
                                        v-model="condition.area_id"
                                        :options="AreaLevel.options()"
                                        empty_option="全て"
                                    ></form-select>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">ステータス</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.status_operator"
                                            :options="StatusOperator.options()"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.status_in"
                                            :options="Status.options()"
                                            class="custom-multiselect-tag"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                                <div class="col-12">
                                <label class="form-label">確実度</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.certainty_operator"
                                            :options="Operator.options()"
                                            :disabled="specified_department_id === null"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.certainty_in"
                                            :options="specified_department_id ? options_certainty[specified_department_id] : []"
                                            :key="certainty_in_reset_key"
                                            :disabled="specified_department_id === null"
                                            class="custom-multiselect-tag"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">優先度</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.probability_operator"
                                            :options="Operator.options()"
                                            :disabled="specified_department_id === null"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.probability_in"
                                            :options="specified_department_id ? options_probability[specified_department_id] : []"
                                            :key="probability_in_reset_key"
                                            :disabled="specified_department_id === null"
                                            class="custom-multiselect-tag"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">評価</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.rank_operator"
                                            :options="Operator.options()"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.rank_in"
                                            :options="Rank.options()"
                                            class="custom-multiselect-tag"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">サブ評価</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.detailed_rank_operator"
                                            :options="Operator.options()"
                                            :disabled="specified_department_id === null"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.detailed_rank_in"
                                            :options="specified_department_id ? options_detailed_rank[specified_department_id] : []"
                                            :key="detailed_rank_in_reset_key"
                                            :disabled="specified_department_id === null"
                                            class="custom-multiselect-tag"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">規格</label>
                                    <form-select-search-multiple
                                        v-model="condition.standard_in"
                                        :options="Standard.options()"
                                        class="custom-multiselect-tag"
                                    ></form-select-search-multiple>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">次回アプローチ日</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.next_approaching_date_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.next_approaching_date_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.next_approaching_date_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">反響日</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.contact_date_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.contact_date_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.contact_date_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">初回アポイント日時</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.first_appointment_datetime_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.first_appointment_datetime_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.first_appointment_datetime_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">最新アポイント日時</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.latest_appointment_datetime_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.latest_appointment_datetime_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.latest_appointment_datetime_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">初回or最新アポイント日時</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.first_latest_appointment_datetime_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.first_latest_appointment_datetime_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.first_latest_appointment_datetime_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">初回アポ取得日</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.first_making_appointment_date_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.first_making_appointment_date_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.first_making_appointment_date_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">最新アポ取得日</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.latest_making_appointment_date_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.latest_making_appointment_date_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.latest_making_appointment_date_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">認証有効期限日</label>
                                    <div class="input-group search_option">
                                        <form-input-date
                                            v-model="condition.certification_expiry_date_from"
                                        ></form-input-date>
                                        <span class="input-group-text">〜</span>
                                        <form-input-date
                                            v-model="condition.certification_expiry_date_to"
                                        ></form-input-date>
                                        <label class="form-control">
                                            <form-checkbox
                                                v-model="condition.certification_expiry_date_is_null"
                                                option_label="未設定"
                                                class="inline-checkbox"
                                            ></form-checkbox>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">契約ステータス</label>
                                    <form-select
                                        v-model="condition.contract_status"
                                        :options="ContractStatus.options()"
                                        empty_option="全て"
                                    ></form-select>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">キャンペーンNo</label>
                                    <form-input v-model="condition.campaign_number"></form-input>
                                </div>
                            </div>
                            <div class="row mb-3 align-items-start">
                                <div class="col-12">
                                    <label class="form-label">アポ取得リスト</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.appointment_source_operator"
                                            :options="Operator.options()"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.appointment_source_in"
                                            :options="options_appointment_source"
                                            class="custom-multiselect-tag"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <label class="form-label">チャネル</label>
                                    <div class="input-group search_option">
                                        <form-select
                                            v-model="condition.channel_operator"
                                            :options="Operator.options()"
                                        ></form-select>
                                        <form-select-search-multiple
                                            v-model="condition.channel_in"
                                            :options="options_channel"
                                            class="custom-multiselect-tag"
                                        ></form-select-search-multiple>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">HOT/COLD</label>
                                    <form-select
                                        v-model="condition.temperature"
                                        :options="Temperature.options()"
                                        empty_option="全て"
                                    ></form-select>
                                </div>
                                <div class="col-6">
                                    <label class="form-label">カウント外案件</label>
                                    <form-checkbox
                                        v-model="condition.exclude_no_count"
                                        option_label="除外する"
                                    ></form-checkbox>
                                </div>
                            </div>
                            <div class="row align-items-start">
                                <div class="col-lg-14 col-md-22 col-30 d-flex align-items-end justify-content-end ms-auto">
                                    <div class="flex-grow-2 p-1">
                                        <label class="form-label">並び順</label>
                                        <form-select
                                            v-model="condition.orderby"
                                            :options="ProjectOrderBy.options()"
                                        ></form-select>
                                    </div>
                                    <div class="flex-grow-1 p-1">
                                        <button type="button" class="btn btn-outline-primary ms-auto" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="applyDisplayFields()">
                                            <i class="bi bi-gear-fill"></i> 表示項目
                                        </button>
                                    </div>
                                    <div class="flex-grow-1 p-1 text-end">
                                        <button
                                            type="submit"
                                            :disabled="loading_count > 0"
                                            class="btn btn-primary btn-lg"
                                        >
                                            <i class="bi bi-search"></i> 検索
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div v-if="$store.getters['auth/canEditProject']" class="row align-items-start">
                            <h3><i class="bi bi-list-ul text-primary"></i> 検索リスト</h3>
                            <div class="col-4">
                                <form-select
                                    v-model="search_list.is_update"
                                    :options="IsUpdate.options()"
                                ></form-select>
                            </div>
                            <template v-if="search_list.is_update === IsUpdate.NO">
                                <div class="col-9">
                                    <form-input
                                        v-model="search_list.condition_name"
                                        placeholder="リスト名を入力"
                                        required
                                    ></form-input>
                                </div>
                                <div class="col-4">
                                    <form-select
                                        v-model="search_list.condition_type"
                                        :options="ConditionType.options()"
                                    ></form-select>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-outline-primary" @click="createOrUpdateSearchList()" type="button">
                                        保存
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="col-9">
                                    <form-select-search
                                        v-model="search_list.condition_id"
                                        :options="$store.getters['auth/canEditSharedList'] ? options_condition : options_user_condition"
                                        @selected="setListToCondition"
                                        placeholder="リストを選択してください"
                                    ></form-select-search>
                                </div>
                                <div class="col-6">
                                    <button class="btn btn-outline-primary" @click="confirmUpdateCondition" type="button">
                                        保存
                                    </button>
                                </div>
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        <div class="alert-secondary p-4 mb-3">
                            <p v-if="condition.keyword">フリーワード：{{ condition.keyword }}</p>
                            <p v-if="condition.department_in && condition.department_in.length > 0">事業部：{{ Operator.get(condition.department_operator) }}：{{ displayDepartments() }}</p>
                            <p v-if="condition.contact_user_id">反響対応者：{{ userName(condition.contact_user_id) }}</p>
                            <p v-if="condition.follow_user_id">追客担当：{{ userName(condition.follow_user_id) }}</p>
                            <p v-if="condition.sales_user_id">営業担当：{{ userName(condition.sales_user_id) }}</p>
                            <p v-if="condition.area_id">エリア別：{{ areaLevelName() }}</p>
                            <p v-if="condition.status_in && condition.status_in.length > 0">ステータス：{{ Operator.get(condition.status_operator) }}：{{ Status.join(condition.status_in) }}</p>
                            <p v-if="condition.certainty_in && condition.certainty_in.length > 0">確実度： {{ Operator.get(condition.certainty_operator) }}：{{ displayCertainties() }}</p>
                            <p v-if="condition.probability_in && condition.probability_in.length > 0">優先度：{{ Operator.get(condition.probability_operator) }}：{{ displayProbabilities() }}</p>
                            <p v-if="condition.rank_in && condition.rank_in.length > 0">評価：{{ Operator.get(condition.rank_operator) }}：{{ Rank.join(condition.rank_in) }}</p>
                            <p v-if="condition.detailed_rank_in && condition.detailed_rank_in.length > 0">サブ評価：{{ Operator.get(condition.detailed_rank_operator) }}：{{ displayDetailedRanks() }}</p>
                            <p v-if="condition.standard_in && condition.standard_in.length > 0">規格：{{ Standard.join(condition.standard_in) }}</p>

                            <template v-if="Number(condition.next_approaching_date_is_null)">
                                <p>次回アプローチ日：未設定</p>
                            </template>
                            <template v-if="!Number(condition.next_approaching_date_is_null) && (condition.next_approaching_date_from || condition.next_approaching_date_to)">
                                <p>次回アプローチ日：{{ $helper.ymd(condition.next_approaching_date_from) }} 〜 {{ $helper.ymd(condition.next_approaching_date_to) }}</p>
                            </template>

                            <template v-if="Number(condition.contact_date_is_null)">
                                <p>反響日：未設定</p>
                            </template>
                            <template v-if="!Number(condition.contact_date_is_null) && (condition.contact_date_from || condition.contact_date_to)">
                                <p>反響日：{{ $helper.ymd(condition.contact_date_from) }} 〜 {{ $helper.ymd(condition.contact_date_to) }}</p>
                            </template>

                            <template v-if="Number(condition.first_appointment_datetime_is_null)">
                                <p>初回アポイント日時：未設定</p>
                            </template>
                            <template v-if="!Number(condition.first_appointment_datetime_is_null) && (condition.first_appointment_datetime_from || condition.first_appointment_datetime_to)">
                                <p>初回アポイント日時：{{ $helper.ymd(condition.first_appointment_datetime_from) }} 〜 {{ $helper.ymd(condition.first_appointment_datetime_to) }}</p>
                            </template>

                            <template v-if="Number(condition.latest_appointment_datetime_is_null)">
                                <p>最新アポイント日時：未設定</p>
                            </template>
                            <template v-if="!Number(condition.latest_appointment_datetime_is_null) && (condition.latest_appointment_datetime_from || condition.latest_appointment_datetime_to)">
                                <p>最新アポイント日時：{{ $helper.ymd(condition.latest_appointment_datetime_from) }} 〜 {{ $helper.ymd(condition.latest_appointment_datetime_to) }}</p>
                            </template>

                            <template v-if="Number(condition.first_latest_appointment_datetime_is_null)">
                                <p>最新アポイント日時：未設定</p>
                            </template>
                            <template v-if="!Number(condition.first_latest_appointment_datetime_is_null) && (condition.first_latest_appointment_datetime_from || condition.first_latest_appointment_datetime_to)">
                                <p>初回or最新アポイント日時：{{ $helper.ymd(condition.first_latest_appointment_datetime_from) }} 〜 {{ $helper.ymd(condition.first_latest_appointment_datetime_to) }}</p>
                            </template>

                            <template v-if="Number(condition.first_making_appointment_date_is_null)">
                                <p>初回アポ取得日：未設定</p>
                            </template>
                            <template v-if="!Number(condition.first_making_appointment_date_is_null) && (condition.first_making_appointment_date_from || condition.first_making_appointment_date_to)">
                                <p>初回アポ取得日：{{ $helper.ymd(condition.first_making_appointment_date_from) }} 〜 {{ $helper.ymd(condition.first_making_appointment_date_to) }}</p>
                            </template>

                            <template v-if="Number(condition.latest_making_appointment_date_is_null)">
                                <p>最新アポ取得日：未設定</p>
                            </template>
                            <template v-if="!Number(condition.latest_making_appointment_date_is_null) && (condition.latest_making_appointment_date_from || condition.latest_making_appointment_date_to)">
                                <p>最新アポ取得日：{{ $helper.ymd(condition.latest_making_appointment_date_from) }} 〜 {{ $helper.ymd(condition.latest_making_appointment_date_to) }}</p>
                            </template>

                            <template v-if="Number(condition.certification_expiry_date_is_null)">
                                <p>認証有効期限日：未設定</p>
                            </template>
                            <template v-if="!Number(condition.certification_expiry_date_is_null) && (condition.certification_expiry_date_from || condition.certification_expiry_date_to)">
                                <p>認証有効期限日：{{ $helper.ymd(condition.certification_expiry_date_from) }} 〜 {{ $helper.ymd(condition.certification_expiry_date_to) }}</p>
                            </template>

                            <p v-if="condition.contract_status">契約ステータス：{{ ContractStatus.get(condition.contract_status) }}</p>                            <p v-if="condition.appointment_source_in && condition.appointment_source_in.length > 0">アポ取得リスト：{{ Operator.get(condition.appointment_source_operator) }}：{{ displayAppointmentSources() }}</p>
                            <p v-if="condition.campaign_number">キャンペーンNo：{{ condition.campaign_number }}</p>                            <p v-if="condition.appointment_source_in && condition.appointment_source_in.length > 0">アポ取得リスト：{{ Operator.get(condition.appointment_source_operator) }}：{{ displayAppointmentSources() }}</p>
                            <p v-if="condition.channel_in && condition.channel_in.length > 0">チャネル：{{ Operator.get(condition.channel_operator) }}：{{ displayChannels() }}</p>
                            <p v-if="condition.temperature">HOT/COLD：{{ Temperature.get(condition.temperature) }}</p>
                            <p v-if="condition.exclude_no_count">カウント外案件：除外する</p>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-lg-14 col-md-22 col-30 d-flex align-items-end justify-content-end ms-auto">
                                <div class="flex-grow-2 p-1">
                                    <label class="form-label">並び順</label>
                                    <form-select
                                        v-model="condition.orderby"
                                        :options="ProjectOrderBy.options()"
                                    ></form-select>
                                </div>
                                <div class="flex-grow-1 p-1">
                                    <button type="button" class="btn btn-outline-primary ms-auto" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="applyDisplayFields()">
                                        <i class="bi bi-gear-fill"></i> 表示項目
                                    </button>
                                </div>
                                <div class="flex-grow-1 p-1 text-end">
                                    <button
                                        @click.prevent="search()"
                                        :disabled="loading_count > 0"
                                        class="btn btn-primary btn-lg"
                                    >
                                        <i class="bi bi-search"></i> 検索
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="displayFieldModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="displayFieldModalLabel">表示設定項目</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body my-3">
                                    <div class="draggable-group">
                                        <draggable
                                                v-model="selected_display_fields"
                                                tag="ul"
                                                group="displayFields"
                                        >
                                            <template #item="{element}">
                                                <li>{{ element.label }}</li>
                                            </template>
                                        </draggable>
                                        <draggable
                                                v-model="unselected_display_fields"
                                                tag="ul"
                                                group="displayFields"
                                        >
                                            <template #item="{element}">
                                                <li>{{ element.label }}</li>
                                            </template>
                                        </draggable>
                                        <!--
                                        <h3 class="mb-2">案件情報</h3>
                                        <div class="d-flex flex-wrap">
                                            <template v-for="field in DisplayFieldService.all()" :key="field.id">
                                                <template v-if="field.category === 'project'">
                                                    <div class="mb-2">
                                                        <input class="form-check-input me-2" type="checkbox" :value="field.id" v-model="selected_display_fields" :id="field.id">
                                                        <label class="form-check-inline" :for="field.id">{{ DisplayFieldService.find(field.id).label }}</label>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>

                                        <h3 class="mt-5 mb-2">客先主担当者</h3>
                                        <div class="d-flex flex-wrap">
                                            <template v-for="field in DisplayFieldService.all()" :key="field.id">
                                                <template v-if="field.category === 'contact'">
                                                    <div class="mb-2">
                                                        <input class="form-check-input me-2" type="checkbox" :value="field.id" v-model="selected_display_fields" :id="field.id">
                                                        <label class="form-check-inline" :for="field.id">{{ DisplayFieldService.find(field.id).label }}</label>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>

                                        <h3 class="mt-5 mb-2">顧客情報</h3>
                                        <div class="d-flex flex-wrap">
                                            <template v-for="field in DisplayFieldService.all()" :key="field.id">
                                                <template v-if="field.category === 'customer'">
                                                    <div class="mb-2">
                                                        <input class="form-check-input me-2" type="checkbox" :value="field.id" v-model="selected_display_fields" :id="field.id">
                                                        <label class="form-check-inline" :for="field.id">{{ DisplayFieldService.find(field.id).label }}</label>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                        -->
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="changeDisplayFields()">表示項目を変更</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <!-- 案件一覧 -->
        <section class="section">
            <div class="row ms-1 mb-3 ps-3 align-items-center">
                <p class="col-8 my-0">該当 : {{ projects_total }} 件</p>
                <div v-if="projects_total > 0" class="col-28 d-flex justify-content-end">
                    <div v-if="$store.getters['auth/canEditProject']" class="ms-auto me-3">
                        <div class="input-group">
                            <form-select
                                v-model="select_or_all"
                                :options="options_select_or_all"
                                model_type="string"
                            ></form-select>
                            <a @click.prevent="routeBulkUpdate()" class="btn btn-outline-primary">一括変更</a>
                        </div>
                    </div>
                    <button
                        class="btn btn-outline-primary me-3"
                        @click="downloadCsv()"
                    >
                        <i class="bi bi-download"></i> CSVダウンロード
                    </button>
                    <button
                        v-if="$store.getters['auth/canEditProject']"
                        class="btn btn-outline-primary me-3"
                        @click="toggleListModeOrSheetMode()"
                        type="button"
                    >
                        <i class="bi bi bi-toggle-on"></i> {{ list_mode_text }}
                    </button>
                </div>
            </div>

            <!-- リストモード -->
            <template v-if="is_list_mode">
                <div v-if="projects.length" class="sticky-table" ref="table">
                    <table
                        class="table table-sm scrollable-table"
                        @mousedown="handleMouseDown"
                        @mousemove="handleMouseMove"
                        @mouseup="handleMouseUp"
                        @mouseleave="handleMouseUp"
                    >
                        <thead>
                            <tr class="table-dark table-head">
                                <th>
                                    <form-checkbox
                                        @change="setAllProjectIdsToBulkUpdate()"
                                        class="text-center checkbox-border-none checkbox-bg px-0"
                                        v-model="is_checked"
                                    >
                                    </form-checkbox>
                                </th>
                                <th></th>
                                <template v-for="field_id in condition.display_fields" :key="field_id">
                                    <th class="text-center px-3" :style="('min-width:' + DisplayFieldService.find(field_id).width + 'px')">
                                        {{ DisplayFieldService.find(field_id).label }}
                                    </th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="project,row in projects" :key="project.project_id" @click="moveToEdit(project.project_id)" class="table-row-clickable">
                                <td @click.stop>
                                    <form-checkbox
                                        @change="setProjectIdsToBulkUpdate(project.project_id)"
                                        class="text-center checkbox-border-none px-0"
                                        :checked="project_ids_to_bulk_update.includes(project.project_id)"
                                    >
                                    </form-checkbox>
                                </td>
                                <td @click.stop>
                                    <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}" class="btn btn-link" target="_blank">{{ row + 1 }}</router-link>
                                </td>
                                <template
                                    v-for="(display_property_name, index) in display_property_names(project, condition.display_fields)"
                                    :key="project.project_id + '00000' + index"
                                >
                                    <td class="align-middle text-start px-3">{{ display_property_name }}</td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>

            <!-- シートモード -->
            <template v-if="!is_list_mode">
                <div v-if="projects.length" class="sticky-table">
                    <table class="table table-sm w-100">
                        <thead>
                            <tr class="table-dark text-nowrap">
                                <th class="text-center w-ms">顧客名</th>
                                <th class="text-center w-mm"><span class="small">追客担当 / 次回アプローチ日</span></th>
                                <th class="text-center w-mm"><span class="small">優先度/確実度/評価/サブ評価/見込日</span></th>
                                <th class="text-center w-ml">追客計画</th>
                                <th class="text-center w-ml">注意事項</th>
                                <th class="text-center w-ml">フィードバック</th>
                                <th class="text-center w-ss"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="project in projects" :key="project.project_id">
                                <td class="align-middle p-1 text-break">
                                    <router-link :to="{name: 'ProjectEdit', params: {id: project.project_id}}">
                                        {{ project.customer.company_name }}
                                    </router-link>
                                    <template v-if="project.standards.length > 0">
                                        <div class="d-flex flex-wrap gap-1 mt-4">
                                            <template v-for="standard in project.standards" :key="standard.standard" class="d-flex flex-column">
                                                <span class="badge bg-primary small">{{ Standard.get(standard.standard) }}</span>
                                            </template>
                                            <span v-if="project.standard_note" class="badge bg-primary small">{{ project.standard_note }}</span>
                                        </div>
                                    </template>
                                </td>
                                <td class="align-middle text-center p-1">
                                    <div class="d-flex flex-column">
                                        <form-select-search
                                            v-model="project.follow_user.user_id"
                                            :options="options_user"
                                            placeholder="追客担当"
                                        ></form-select-search>
                                        <form-input-date
                                            v-model="project.next_approaching_date"
                                            optional_class="mt-4"
                                            placeholder="次回アプローチ日"
                                        ></form-input-date>
                                    </div>
                                </td>
                                <td class="align-middle text-center p-1">
                                    <div>
                                        <form-select-search
                                            v-model="project.probability.probability_id"
                                            :options="options_probability[project.department.department_id]"
                                            placeholder="優先度"
                                        ></form-select-search>
                                    </div>
                                    <div>
                                        <form-select-search
                                            v-model="project.certainty.certainty_id"
                                            :options="options_certainty[project.department.department_id]"
                                            placeholder="確実度"
                                        ></form-select-search>
                                    </div>
                                    <div>
                                        <form-select-search
                                            v-model="project.rank"
                                            :options="Rank.options()"
                                            placeholder="評価"
                                        ></form-select-search>
                                    </div>
                                    <div>
                                        <form-select-search
                                            v-model="project.detailed_rank.detailed_rank_id"
                                            :options="options_detailed_rank[project.department.department_id]"
                                            placeholder="サブ評価"
                                        ></form-select-search>
                                    </div>
                                    <div>
                                        <form-input-date
                                            v-model="project.expected_order_date"
                                            placeholder="見込日"
                                        ></form-input-date>
                                    </div>
                                    <div>
                                        <div class="input-group">
                                            <form-input-number
                                                v-model="project.proposal_price"
                                                placeholder="提案金額"
                                            ></form-input-number>
                                            <div class="input-group-text">万円</div>
                                        </div>
                                    </div>
                                </td>
                                <td class="align-middle">
                                    <form-textarea row="6" v-model="project.follow_plan"></form-textarea>
                                </td>
                                <td class="align-middle">
                                    <form-textarea row="6" v-model="project.sales_note"></form-textarea>
                                </td>
                                <td class="align-middle">
                                    <form-textarea row="6" v-model="project.latest_feedback.feedback_detail"></form-textarea>
                                </td>
                                <td class="align-middle text-center">
                                    <button class="btn btn-primary" @click.prevent="updateProject(project)" type="button">
                                        保存
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>

            <!-- ページネーション -->
            <paginator :meta="paginator" @move="search" class="mt-5"></paginator>
        </section>
    </template>

    <confirm-dialog ref="confirmUpdateCondition" @ok="createOrUpdateSearchList()">
        <p>この検索リストが「共通用」の場合、他の人にも検索条件が上書きされてしまいます。<br>本当に保存してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
/*
# 詳細検索
condition : 検索条件のオブジェクト（表示項目や並び順も含む）

# リスト検索
search_list : 現在選択中のリスト（但しセレクトボックスには search_list.condition_id のみが bind されている）
options_condition : 選択肢のリスト {key, label}
conditions : options_condition の元となる Condition オブジェクトの配列

検索時（ search() メソッド実行時）に、
search_list.condition_id をもとに conditions から該当のリストを探し、
condition にセットした上で検索している。

セレクトボックス変更時（ setListToCondition() メソッド実行時）に
何かいろいろやってるがよくわからない・・・
condition_id_before_change : ひとつ前に選択していたリスト
→ 何に使っている？

# 表示項目設定
selected_display_fields : モーダル内のチェックボックス (display-field オブジェクト の配列)
モーダルを開く際に applyDisplayFields() で condition.display_fields からコピー -> 効いていない？
モーダルを閉じる際に changeDisplayFields() で condition.display_fields へコピー

*/
// import { dateFormat, now, latestDate } from '@/utilities/date-format';
import { downloadBlob } from '@/utilities/download';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Paginator from '@/components/tools/Paginator';
import DisplayFieldService from '@/models/services/display-filed-service';
import FormSelect from '@/components/forms/FormSelect';
import FormInput from '@/components/forms/FormInput';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import FormTextarea from '@/components/forms/FormTextarea';
import Rank from '@/models/enums/rank';
import Status from '@/models/enums/status';
import Temperature from '@/models/enums/temperature';
import ContractStatus from '@/models/enums/contract-status';
import Standard from '@/models/enums/standard';
import Operator from '@/models/enums/operator';
import StatusOperator from '@/models/enums/status-operator';
import ProjectOrderBy from '@/models/enums/project-order-by';
import IsUpdate from '@/models/enums/is-update';
import ConditionType from '@/models/enums/condition-type';
import IsSheetMode from '@/models/enums/is-sheet-mode';
import AreaLevel from '@/models/enums/area-level';
import Project from '@/models/entities/project';
import Department from '@/models/entities/department';
import User from '@/models/entities/user';
import Area from '@/models/entities/area';
import Certainty from '@/models/entities/certainty';
import Probability from '@/models/entities/probability';
import DetailedRank from '@/models/entities/detailed-rank';
import AppointmentSource from '@/models/entities/appointment-source';
import Channel from '@/models/entities/channel';
import Condition from '@/models/entities/condition';
import Feedback from '@/models/entities/feedback';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import Role from '@/models/enums/role';
import draggable from 'vuedraggable';

export default {
    name: 'PageProject',
    components: {
        // フォーム構成品
        FormSelect,
        FormInput,
        FormInputDate,
        FormInputNumber,
        FormCheckbox,
        FormSelectSearch,
        FormSelectSearchMultiple,
        FormTextarea,
        InlineLoader,
        Paginator,
        ConfirmDialog,
        draggable,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // ページネーション
            paginator: {},

            // リスト固定中 切り替え
            fixed_list_text: 'リスト固定',

            list_mode_text: 'リストモード',

            // 検索開始フラグ
            search_stared: false,

            // リストモード 案件一覧
            projects: [],
            projects_total: null,

            // ログイン中ユーザ
            user: null,

            // 表示項目（デフォルトは空で）
            selected_display_fields: [],
            unselected_display_fields: [],

            // 変更前のリストID
            condition_id_before_change: null,

            // 再レンダリング key
            certainty_in_reset_key: 0,
            probability_in_reset_key: 0,
            detailed_rank_in_reset_key: 0,

            // entity
            conditions: [],

            // entity options
            options_department: [],
            options_user: [],
            options_area: [],
            options_certainty: [],
            options_probability: [],
            options_detailed_rank: [],
            options_appointment_source: [],
            options_channel: [],
            options_condition: [],
            options_user_condition: [],
            options_select_or_all: [
                {value: 'select', label: '選択したものを'},
                {value: 'all', label: '検索結果全てを'},
            ],

            // enum options
            Status,
            StatusOperator,
            Rank,
            Temperature,
            ContractStatus,
            Standard,
            Operator,
            ProjectOrderBy,
            IsUpdate,
            ConditionType,
            AreaLevel,
            Role,

            show_search_form: false,
            condition: {
                keyword: null,
                department_in: null,
                department_operator: Operator.CONTAIN,
                contact_user_id: null,
                follow_user_id: null,
                sales_user_id: null,
                area_id: null,
                status_in: null,
                status_operator: StatusOperator.CONTAIN,
                certainty_in: null,
                certainty_operator: Operator.CONTAIN,
                probability_in: null,
                probability_operator: Operator.CONTAIN,
                rank_in: null,
                rank_operator: Operator.CONTAIN,
                detailed_rank_in: null,
                detailed_rank_operator: Operator.CONTAIN,
                standard_in: null,
                next_approaching_date_from: null,
                next_approaching_date_to: null,
                next_approaching_date_is_null: null,
                contact_date_from: null,
                contact_date_to: null,
                contact_date_is_null: null,
                first_appointment_datetime_from: null,
                first_appointment_datetime_to: null,
                first_appointment_datetime_is_null: null,
                latest_appointment_datetime_from: null,
                latest_appointment_datetime_to: null,
                latest_appointment_datetime_is_null: null,
                first_latest_appointment_datetime_from: null,
                first_latest_appointment_datetime_to: null,
                first_latest_appointment_datetime_is_null: null,
                first_making_appointment_date_from: null,
                first_making_appointment_date_to: null,
                first_making_appointment_date_is_null: null,
                latest_making_appointment_date_from: null,
                latest_making_appointment_date_to: null,
                latest_making_appointment_date_is_null: null,
                certification_expiry_date_from: null,
                certification_expiry_date_to: null,
                certification_expiry_date_is_null: null,
                contract_status: null,
                campaign_number: null,
                appointment_source_operator: Operator.CONTAIN,
                appointment_source_in: null,
                channel_operator: Operator.CONTAIN,
                channel_in: null,
                temperature: null,
                exclude_no_count: 0,
                orderby: ProjectOrderBy.NEXT_APPROACHING_DATE_ASC,
                display_fields: [10, 30, 40, 50, 60, 70, 120, 600, 700], //デフォルト設定（ソートは display-fields.json で指定）
                page: 1,
                per_page: null,
            },

            // 検索リスト
            search_list: {
                is_update: IsUpdate.NO,
                condition_name: null,
                condition_id: null,
                condition_type: ConditionType.PRIVATE,
            },

            // service
            DisplayFieldService: DisplayFieldService,

            // 案件一括更新対象project
            select_or_all: 'select',
            project_ids_to_bulk_update: [],
            is_checked: 0,

            // マウスドラッグでテーブル左右にスクロール
            table: null,
            is_mouse_down: false,
            // before_mouse_x, after_mouse_xが同じならクリック、異なるなら横スクロールと判定させる
            before_mouse_x: null,
            after_mouse_x: null,
            scroll_left: 0,
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        // リスト/詳細検索 切り替え
        is_list: {
            get() {
                return this.$store.state.projectList.is_list;
            },
            set(value) {
                this.$store.commit('projectList/setIsList', value);
            }
        },
        // リストモード/シートモード 切り替え
        is_list_mode: {
            get() {
                return this.$store.state.projectList.is_list_mode;
            },
            set(value) {
                this.$store.commit('projectList/setIsListMode', value);
            }
        },
        display_property_names () {
            return (project, display_fields) => {
                return DisplayFieldService.filterDisplayProperty(project, display_fields);
            }
        },
        // 単一の部門のみが選択されている場合のみ、IDを返す
        specified_department_id() {
            if (this.condition.department_operator !== StatusOperator.CONTAIN) {
                return null;
            }

            if (!this.condition.department_in) {
                return null;
            }

            if (this.condition.department_in.length !== 1) {
                return null;
            }

            return this.condition.department_in[0];
        },
    },
    methods: {
        // リスト/詳細検索切り替え
        toggleListModeOrSheetMode() {
            this.is_list_mode = !this.is_list_mode;
            this.list_mode_text = (this.is_list_mode) ? 'シートモード' : 'リストモード';
        },
        // リスト固定 表示切り替え
        changeSearchListText() {
            this.fixed_list_text = (this.search_list.condition_id === this.user.condition_id) ? 'リスト解除' : 'リスト固定';
        },
        // 案件詳細へ遷移
        moveToEdit(project_id) {
            // クリックしたと判定させるには下記条件が必要
            // マウスドラッグ時には横スクロールさせるため
            if (
                (this.before_mouse_x !== null && this.after_mouse_x !== null)
                && (this.before_mouse_x === this.after_mouse_x)
            ) {
                this.$router.push({name: 'ProjectEdit', params: {id: project_id}});
            }
        },
        // リストの検索条件を検索パラメータへセット
        setListToCondition(new_condition_id) {
            // 変更前のリストへ設定を残す
            if (this.condition_id_before_change !== null) {
                this.conditions.forEach((before_condition) => {
                    if (before_condition.condition_id === this.condition_id_before_change) {
                        for (let key in before_condition) {
                            if (key in this.condition) {
                                before_condition[key] = this.condition[key];
                            }
                        }
                    }
                });
            }

            // 新規
            if (this.search_list.condition_id === null || this.search_list.is_update === IsUpdate.NO) {
                this.condition_id_before_change = null;
                return;
            }

            // 上書き : 変更後のリストの設定を反映
            const selected_condition = this.conditions.find((condition) => {
                if (this.$helper.isBlank(new_condition_id)) {
                    return condition.condition_id === this.search_list.condition_id;
                } else {
                    return condition.condition_id === new_condition_id;
                }
            });
            if (this.$helper.isBlank(selected_condition)) {
                return;
            }
            this.condition_id_before_change = selected_condition.condition_id;

            for (let key in selected_condition) {
                if (key in this.condition) {
                    this.condition[key] = selected_condition[key];
                }
            }
            this.search_list.condition_type = selected_condition.condition_type;
            this.search_list.condition_name = selected_condition.condition_name;

            this.changeSearchListText();
            this.updateDepartmentLinkedItems(false);
        },
        // 表示項目変更を検索リストへ反映（キャンセル時に元設定をセット）
        applyDisplayFields () {
            this.selected_display_fields = DisplayFieldService.fieldIdsToObject(this.condition.display_fields);
            this.unselected_display_fields = DisplayFieldService.fieldIdsToObjectExcept(this.condition.display_fields);
        },
        // 表示項目変更
        changeDisplayFields () {
            this.condition.display_fields = this.selected_display_fields.map(row => row.id); //値渡し
        },
        // 有効無効切り替え / 事業部に紐づくマスタ取得
        updateDepartmentLinkedItems(clear) {
            if (!this.search_stared) {
                return;
            }

            // 事業部の変更時のみ選択肢を消す
            if (this.loading_count < 1 && clear) {
                this.condition.certainty_in = null;
                this.condition.probability_in = null;
                this.condition.detailed_rank_in = null;
            }

            // 条件が含む、かつ選択が1つのみの場合のみ以下の処理を実施
            if (this.specified_department_id === null) {
                return;
            }

            if (this.options_certainty[this.specified_department_id].length === 0) {
                // 選択肢を一度も取得できていなければ取得
                if (
                    !this.options_probability[this.specified_department_id].length &&
                    !this.options_detailed_rank[this.specified_department_id].length
                ) {
                    this.fetchCertaintyOptions(this.specified_department_id);
                }
            }

            if (this.options_probability[this.specified_department_id].length === 0) {
                // 選択肢を一度も取得できていなければ取得
                if (
                    !this.options_certainty[this.specified_department_id].length &&
                    !this.options_detailed_rank[this.specified_department_id].length
                ) {
                    this.fetchProbabilityOptions(this.specified_department_id);
                }
            }

            if (this.options_detailed_rank[this.specified_department_id].length === 0) {
                // 選択肢を一度も取得できていなければ取得
                if (
                    !this.options_certainty[this.specified_department_id].length &&
                    !this.options_probability[this.specified_department_id].length
                ) {
                    this.fetchDetailedRankOptions(this.specified_department_id);
                }
            }
        },
        // リスト固定
        updateFixList() {
            this.startScreenLoading();

            if (this.search_list.condition_id === this.user.condition_id) {
                // リスト固定の解除
                this.user.condition_id = null;
            } else {
                // 検索リストをユーザ固定リストへ同期
                this.user.condition_id = this.search_list.condition_id;
            }

            this.$http.put(`/system/user/${this.user.user_id}`, this.user)
            .then(() => {
                // リスト固定 表示切り替え
                this.changeSearchListText();

                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 検索リスト更新 確認ダイアログ
        confirmUpdateCondition() {
            this.$refs.confirmUpdateCondition.show();
        },
        // 検索リスト 登録/更新
        createOrUpdateSearchList() {
            this.startScreenLoading();

            //バリデーション
            if (this.search_list.is_update === IsUpdate.NO) {
                if (this.$helper.isBlank(this.search_list.condition_name)) {
                    this.endScreenLoading();
                    this.showErrorMessage('リスト名を入力してください');
                    return;
                }
            }

            let condition = new Condition(this.condition);
            condition.user_id = (this.search_list.condition_type === ConditionType.PRIVATE) ? this.user.user_id : null;
            condition.condition_type = this.search_list.condition_type;
            condition.condition_name = this.search_list.condition_name;

            // 入力後に選択解除すると空配列になる対策
            condition.follow_user_id = Array.isArray(condition.follow_user_id) ? null : condition.follow_user_id;
            condition.contact_user_id = Array.isArray(condition.contact_user_id) ? null : condition.contact_user_id;
            condition.sales_user_id = Array.isArray(condition.sales_user_id) ? null : condition.sales_user_id;

            if (this.search_list.is_update === IsUpdate.YES) {
                // 更新
                this.$http.put(`/condition/${this.search_list.condition_id}`, condition)
                .then(() => {
                    this.showMessage('保存しました');
                })
                .finally(() => {
                    this.endScreenLoading();
                });
            } else {
                // 登録
                this.$http.post('/condition', condition)
                .then((response) => {
                    // マスタへ追加
                    this.conditions.push(response.data);

                    // リスト追加
                    this.options_condition.push({value: response.data.condition_id, label: response.data.condition_name});

                    // 登録したリストを選択状態へ
                    this.search_list.condition_id = response.data.condition_id;

                    // 上書きモードへ切り替え
                    this.search_list.is_update = this.IsUpdate.YES;

                    this.showMessage('保存しました');
                })
                .finally(() => {
                    this.endScreenLoading();
                });
            }
        },
        // シートモード 案件更新
        updateProject (project) {
            this.startScreenLoading();

            this.$http.put(`/project/${project.project_id}`, project)
            .then(() => {
                this.createFeedback(project);
            })
            .finally(() => {
            });
        },
        // シートモード 上長フィードバック新規作成
        createFeedback (project) {
            //フィードバックに何か入力が存在 & 何か変更された 場合のみ
            if (project.latest_feedback.feedback_detail && project.latest_feedback.feedback_detail !== project.latest_feedback_original.feedback_detail) {
                this.$http.post(
                    `/project/${project.project_id}/feedback`,
                    {
                        feedback_detail: project.latest_feedback.feedback_detail,
                        feedback_id: project.latest_feedback.feedback_id,
                        is_sheet_mode: IsSheetMode.YES
                    }
                )
                .then((response) => {
                    const feedback = new Feedback(response.data);
                    if (project.latest_feedback.feedback_id !== feedback.feedback_id) {
                        project.feedbacks.push(feedback);
                    }
                    this.showMessage('保存しました');
                })
                .finally(() => {
                    this.endScreenLoading();
                });
            } else {
                this.endScreenLoading();
            }
        },
        async init() {
            this.loading_count++;

            // ログインユーザ取得
            await this.fetchLoginUser();

            // 事業部とリストを先に取得
            await Promise.all([
                this.fetchDepartmentOptions(),
                this.fetchConditionOptions()
            ]);

            // 検索条件がstoreになければデフォルト検索を実行
            if (this.$store.getters['projectList/condition'] === null) {
                this.setListToCondition();
            }

            let department_ids = this.options_department.map((department) => {
                return department.value;
            });

            // マスタ取得 : リセット
            this.options_probability.splice(0);
            this.options_certainty.splice(0);
            this.options_detailed_rank.splice(0);

            // マスタ取得 : キー準備
            department_ids.forEach((department_id) => {
                this.options_probability[department_id] = [];
                this.options_certainty[department_id] = [];
                this.options_detailed_rank[department_id] = [];
            });

            if (this.specified_department_id === null) {
                await Promise.all([
                    this.fetchUserOptions(),
                    this.fetchAreaOptions(),
                    this.fetchAppointmentSourceOptions(),
                    this.fetchChannelOptions(),
                ]);
            } else {
                await Promise.all([
                    this.fetchUserOptions(),
                    this.fetchAreaOptions(),
                    this.fetchAppointmentSourceOptions(),
                    this.fetchChannelOptions(),
                    this.fetchProbabilityOptions(this.specified_department_id),
                    this.fetchCertaintyOptions(this.specified_department_id),
                    this.fetchDetailedRankOptions(this.specified_department_id),
                ]);
            }

            this.search_stared = true;
            this.updateDepartmentLinkedItems(false);
            this.search(this.condition.page);

            this.loading_count--;
        },
        // 事業部マスタ取得
        fetchDepartmentOptions() {
            return new Promise(resolve => {
                // リセット
                this.options_department.splice(0);

                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        let department = new Department(row);
                        this.options_department.push({value: department.department_id, label: department.department_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // ユーザマスタ取得
        fetchUserOptions() {
            return new Promise(resolve => {
                // リセット
                this.options_user.splice(0);

                this.$http.get('/user')
                .then(response => {
                    for (let row of response.data) {
                        let user = new User(row);
                        this.options_user.push({value: user.user_id, label: user.user_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // ログイン中ユーザ取得
        fetchLoginUser() {
            return new Promise(resolve => {
                this.$http.get('/me')
                .then(response => {
                    this.user = new User(response.data);
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // エリアマスタ取得
        fetchAreaOptions() {
            return new Promise(resolve => {
                // リセット
                this.options_area.splice(0);

                this.$http.get('/master/area')
                .then(response => {
                    for (let row of response.data) {
                        let area = new Area(row);
                        this.options_area.push({value: area.area_id, label: area.area_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // アポ取得リストマスタ取得
        fetchAppointmentSourceOptions() {
            return new Promise(resolve => {
                // リセット
                this.options_appointment_source.splice(0);

                this.$http.get('/master/appointment-source')
                .then(response => {
                    for (let row of response.data) {
                        let appointment_source = new AppointmentSource(row);
                        this.options_appointment_source.push({value: appointment_source.appointment_source_id, label: appointment_source.appointment_source_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // チャネルマスタ取得
        fetchChannelOptions() {
            return new Promise(resolve => {
                // リセット
                this.options_channel.splice(0);

                this.$http.get('/master/channel')
                .then(response => {
                    for (let row of response.data) {
                        let channel = new Channel(row);
                        this.options_channel.push({value: channel.channel_id, label: channel.channel_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 優先度マスタ取得
        fetchProbabilityOptions(department_id) {
            return new Promise(resolve => {
                this.$http.get('/master/probability', {
                    params: {
                        department: department_id
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let probability = new Probability(row);
                        this.options_probability[department_id].push(
                            {value: probability.probability_id, label: probability.probability_name}
                        );
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // サブ評価マスタ取得
        fetchDetailedRankOptions(department_id) {
            return new Promise(resolve => {
                this.$http.get('/master/detailed-rank', {
                    params: {
                        department: department_id
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let detailed_rank = new DetailedRank(row);
                        this.options_detailed_rank[department_id].push(
                            {value: detailed_rank.detailed_rank_id, label: detailed_rank.detailed_rank_name}
                        );
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            });
        },
        // 確実度マスタ取得
        fetchCertaintyOptions(department_id) {
            return new Promise(resolve => {
                this.$http.get('/master/certainty', {
                    params: {
                        department: department_id
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let certainty = new Certainty(row);
                        this.options_certainty[department_id].push(
                            {value: certainty.certainty_id, label: certainty.certainty_name}
                        );
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // リストマスタ取得
        fetchConditionOptions() {
            return new Promise(resolve => {
                // 一応リセット
                this.options_condition.splice(0);
                this.conditions.splice(0);

                this.$http.get('/condition')
                .then(response => {
                    for (let row of response.data) {
                        const condition = new Condition(row);

                        // リスト一覧
                        this.options_condition.push({value: condition.condition_id, label: condition.condition_name});
                        this.conditions.push(condition);

                        // ログインユーザの個人用リスト一覧
                        if (
                            this.$store.state.auth.user_id === condition.user?.user_id
                            && condition.condition_type === ConditionType.PRIVATE
                        ) {
                            this.options_user_condition.push({value: condition.condition_id, label: condition.condition_name});
                        }
                    }

                    // 優先度
                    // 1. condition_idが渡ってきた場合（リスト管理から表示）
                    // 2. store(検索条件保持データ)
                    // 3. ユーザ固定リストあり
                    // 4. デフォルト

                    // 1. condition_idが渡ってきた場合（リスト管理から表示）
                    if (this.$route.params.condition_id) {
                        const list = this.conditions.find(condition => condition.condition_id === Number(this.$route.params.condition_id));

                        this.search_list.is_update = IsUpdate.YES;
                        if (list) {
                            this.search_list.condition_id = list.condition_id;
                            this.search_list.condition_type = list.condition_type;
                        } else if (this.conditions.length) {
                            this.search_list.condition_id = this.conditions[0].condition_id;
                            this.search_list.condition_type = this.conditions[0].condition_type;
                        }
                    }
                    // 2. store(検索条件保持データ)
                    else if (this.$store.getters['projectList/condition'] !== null) {
                        this.condition = this.$store.getters['projectList/condition'];
                        this.search_list = this.$store.state.projectList.search_list;
                    }
                    // 3. ユーザ固定リストあり
                    else if (this.user.condition_id !== null) {
                        const fixed_list = this.conditions.find(condition => condition.condition_id === this.user.condition_id);

                        if (fixed_list) {
                            this.search_list.condition_id = fixed_list.condition_id;
                        } else if (this.conditions.length) {
                            this.search_list.condition_id = this.conditions[0].condition_id;
                        }
                    }
                    // 4. デフォルト
                    else {
                        // ユーザ固定リストなし
                        if (this.conditions.length) {
                            this.search_list.condition_id = this.conditions[0].condition_id;
                        }
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 検索
        search(page = 1) {
            this.loading_count++;
            this.condition.page = page;

            // リストからの検索であれば選択されたリストの検索条件をセット
            if (this.is_list) {
                const selected_condition = this.conditions.find((condition) => {
                    return condition.condition_id === this.search_list.condition_id;
                });
                if (this.$helper.isBlank(selected_condition)) {
                    this.loading_count--;
                    this.showErrorMessage('リストを選択してください');
                    return;
                }
                for (let key in selected_condition) {
                    if (key in this.condition) {
                        if (key !== 'keyword') {
                            this.condition[key] = selected_condition[key];
                        }
                    }
                }
            }
            // 検索条件をstoreにセット
            this.$store.dispatch('projectList/setCondition', this.condition);
            // 検索リストをstoreにセット
            this.$store.commit('projectList/setSearchList', this.search_list);

            // リセット
            this.projects.splice(0);

            // 案件取得
            this.$http.get('/project', {
                params: this.condition,
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.projects.push(new Project(row));
                }

                // ページネーション用
                this.paginator = response.data.meta;
                this.projects_total = response.data.meta.total;
            })
            .finally(() => {
                this.loading_count--;
            })
        },
        //CSVダウンロード
        downloadCsv() {
            this.startScreenLoading();

            this.$http.get('/project/export/csv', {
                responseType: 'blob',
                params: this.condition
            })
            .then(response => {
                let date_obj = new Date();
                let year = date_obj.getFullYear().toString();
                let month = (date_obj.getMonth() + 1).toString().padStart(2, '0');
                let date = date_obj.getDate().toString().padStart(2, '0');
                let filename = year + month + date + '_projects_export.csv';

                if ('content-disposition' in response.headers) {
                    filename = response.headers['content-disposition'].replace((/attachment; filename="(.*)"/u), '$1');
                }

                downloadBlob(response.data, filename);
            })
            .catch(() => {
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        // 検索結果の案件をすべて一括更新対象にセット
        setAllProjectIdsToBulkUpdate() {
            this.project_ids_to_bulk_update.splice(0);

            // 選択されている場合
            if (this.is_edit) {
                // 選択解除
                this.is_edit = 0;
            } else {
            // 選択がされていない場合
                // すべての案件を一括更新対象にセット
                this.projects.forEach((project) => {
                    this.project_ids_to_bulk_update.push(project.project_id);
                });

                this.is_edit = 1;
            }
        },

        // 案件一括更新対象のproject_idセット
        setProjectIdsToBulkUpdate(project_id) {
            if (this.project_ids_to_bulk_update.includes(project_id)) {
            // 既に存在する場合
                const remove_index = this.project_ids_to_bulk_update.findIndex(set_project_id => set_project_id === project_id);
                this.project_ids_to_bulk_update.splice(remove_index, 1);
            } else {
            // 存在しなかった場合
                this.project_ids_to_bulk_update.push(project_id);
            }
        },
        routeBulkUpdate() {
            let query = {
                select_or_all: this.select_or_all
            };

            // 「選択したもの」の場合
            if (this.select_or_all === 'select') {
                // なにもチェックしていなければ即時return
                if (this.project_ids_to_bulk_update.length === 0) {
                    this.showErrorMessage('案件を選択してください');
                    return;
                }

                query.project_ids = this.project_ids_to_bulk_update;
            } else if (this.select_or_all === 'all') {
                // 件数を渡す（検索条件は store で受け渡し）
                query.count = this.paginator.total;
            } else {
                return;
            }

            this.$router.push({name: 'ProjectBulk', query: query});
        },
        handleMouseDown(event) {
            this.table = this.$refs.table;
            this.is_mouse_down = true;
            this.before_mouse_x = event.clientX;
            this.scroll_left = this.table.scrollLeft;
        },
        handleMouseMove(event) {
            if (this.table !== null && this.is_mouse_down === true) {
                const move_x = parseInt(this.before_mouse_x, 10) - event.clientX;
                this.table.scrollLeft = parseInt(this.scroll_left, 10) + move_x;
            }
        },
        handleMouseUp(event) {
            if (this.table !== null && this.is_mouse_down === true){
                this.is_mouse_down = false;
                this.after_mouse_x = event.clientX;
            }
        },
        displayDepartments() {
            const display_departments = this.options_department.filter(department => this.condition.department_in.includes(department.value));
            return display_departments.map(department => department.label).join(', ');
        },
        userName(user_id) {
            return this.options_user.find(user => user.value === user_id).label;
        },
        areaLevelName() {
            return this.AreaLevel.options().find(area => area.value === this.condition.area_id).label;
        },
        displayCertainties() {
            if (this.specified_department_id === null) {
                return "";
            }
            const display_certainties = this.options_certainty[this.specified_department_id].filter(certainty => this.condition.certainty_in.includes(certainty.value));
            return display_certainties.map(certainty => certainty.label).join(', ');
        },
        displayProbabilities() {
            if (this.specified_department_id === null) {
                return "";
            }
            const display_probabilities = this.options_probability[this.specified_department_id].filter(probability => this.condition.probability_in.includes(probability.value));
            return display_probabilities.map(probability => probability.label).join(', ');
        },
        displayDetailedRanks() {
            if (this.specified_department_id === null) {
                return "";
            }
            const display_detailed_ranks = this.options_detailed_rank[this.specified_department_id].filter(detailed_rank => this.condition.detailed_rank_in.includes(detailed_rank.value));
            return display_detailed_ranks.map(detailed_rank => detailed_rank.label).join(', ');
        },
        displayAppointmentSources() {
            const display_appointment_sources = this.options_appointment_source.filter(appointment_source => this.condition.appointment_source_in.includes(appointment_source.value));
            return display_appointment_sources.map(appointment_source => appointment_source.label).join(', ');
        },
        displayChannels() {
            const display_channels = this.options_channel.filter(channel => this.condition.channel_in.includes(channel.value));
            return display_channels.map(channel => channel.label).join(', ');
        },
    }
}
</script>

<style scoped>
    .input-group-multiple + span .selection .select2-selection {
        border-radius: 0 4px 4px 0 !important;
    }
    .search_option {
        flex-wrap: nowrap;
    }
    .search_option select:first-child {
        width: 95px !important;
        flex: 0 1 auto !important;
    }
    .inline-checkbox {
        border: none;
        padding: 0;
    }
    .sticky-table {
        max-width: 100%;
        overflow: scroll;
    }
    .sticky-table th  {
        white-space: nowrap;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }
    .sticky-table th:last-child,
    .sticky-table td:last-child {
        min-width: 80px;

    }
    .table-head {
        height: 27.3px;
    }
    .checkbox-border-none {
        border: none;
    }
    .checkbox-bg {
        background-color: #212529;
    }
    .table-row-clickable:hover td > .checkbox-border-none {
        background-color: var(--bs-light);
    }

    .custom-multiselect-tag .multiselect-tag {
        max-width: 280px !important;
        white-space: normal !important;
    }
    .w-ms {
        width: 10%;
    }
    .w-mm {
        width: 12%;
    }
    .w-ml {
        width: 20%;
    }
    .w-ss {
        width: 5%;
    }
    input::placeholder, select::placeholder {font-size: .9rem;}

    .draggable-group {
        display: flex;
        justify-content: space-between;
    }
    .draggable-group ul {
        flex-basis: 48%;
        background: #EEE;
        padding: 1em;
        list-style-type: none;
    }
    .draggable-group li {
        margin-bottom: 0.1em;
        padding: 0.2em 1em;
        background: #FFF;
        border: 1px solid #888;
    }
</style>
