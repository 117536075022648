import { isBlank } from '@/utilities/typing';
import DisplayField from '@/models/services/display-fields.json';
import IsProblem from '@/models/enums/is-problem';

/**
 * 表示項目リストの取得
 */
class DisplayFieldService
{
    static TEXT_LENGTH = 40; // 表示する最大文字数

    static find(id) {
        if (isBlank(id)) {
            return null;
        }

        let field = DisplayField.find((field) => {
            return field.id === Number(id);
        })

        return field ?? {
            "id": id,
            "key": "",
            "label": "",
            "category": "project",
            "width": 100,
            "sort": 999
        };
    }

    static all() {
        //ソートして返す
        DisplayField.sort((a, b) =>
            a.sort - b.sort
        );
        return DisplayField;
    }

    // display_field のID配列を渡すと、ソートして返す（ID配列を返す）
    static sortDisplayField(display_field_ids) {
        display_field_ids.sort((a_id, b_id) =>
            this.find(a_id).sort - this.find(b_id).sort
        );
    }

    static filterDisplayProperty(
        project,
        display_field_ids,
    ) {
        if (isBlank(project) || isBlank(display_field_ids)) {
            return null;
        }

        //ソート
        //this.sortDisplayField(display_field_ids);

        let fields = [];
        display_field_ids.forEach(display_field_id => {
            let key = this.find(display_field_id).key;
            let field_id = this.find(display_field_id).id;

            if (!field_id) {
                fields.push('');
                return;
            }

            // 事業部マスタ
            if (field_id === 10) {
                fields.push(project[key].department_name);
                return;
            }
            // ユーザマスタ
            if (
                field_id === 20 ||
                field_id === 30 ||
                field_id === 40 ||
                field_id === 210 ||
                field_id === 293 ||
                field_id === 294 ||
                field_id === 510 ||
                field_id === 520
            ) {
                fields.push(project[key].user_name);
                return;
            }
            // 最終更新日
            if (field_id === 521) {
                fields.push(project.updated_datetime.replace(/^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/, '$1/$2/$3'));
                return;
            }
            // ステータス変更日
            if (field_id === 530) {
                fields.push(project.latest_update_status_date.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$1/$2/$3'));
                return;
            }
            // エリア別
            if (field_id === 41) {
                fields.push(project.area.area_level_label);
                return;
            }
            // 優先度マスタ
            if (field_id === 60) {
                fields.push(project[key].probability_name);
                return;
            }
            // サブ評価マスタ
            if (field_id === 80) {
                fields.push(project[key].detailed_rank_name);
                return;
            }
            // 確実度マスタ
            if (field_id === 90) {
                fields.push(project[key].certainty_name);
                return;
            }
            // アポ取得リストマスタ
            if (field_id === 302) {
                fields.push(project[key].appointment_source_name);
                return;
            }
            // 受注日
            if (field_id === 110) {
                fields.push(project.ordered_date_label);
                return;
            }
            // 契約ステータス
            if (field_id === 115) {
                fields.push(project.contract_status_label);
                return;
            }
            // 次回アプローチ日
            if (field_id === 120) {
                fields.push(project.next_approaching_date_label);
                return;
            }
            // 見込日
            if (field_id === 130) {
                fields.push(project.expected_order_date_label);
                return;
            }
            // 反響日
            if (field_id === 160) {
                fields.push(project.contact_date_label);
                return;
            }
            // チャネルマスタ
            if (field_id === 180) {
                fields.push(project[key].channel_name);
                return;
            }
            // 郵便番号
            if (field_id === 220) {
                fields.push(project.zip_code_label);
                return;
            }
            // エリアマスタ
            if (field_id === 230) {
                fields.push(project[key].area_name);
                return;
            }
            // 初回アポイント日時
            if (field_id === 280) {
                fields.push(project.first_appointment_datetime_label);
                return;
            }
            // 最新アポイント日時
            if (field_id === 290) {
                fields.push(project.latest_appointment_datetime_label);
                return;
            }
            // 初回アポ取得日
            if (field_id === 291) {
                fields.push(project.first_making_appointment_date_label);
                return;
            }
            // 最新アポ取得日
            if (field_id === 291) {
                fields.push(project.latest_making_appointment_date_label);
                return;
            }

            // 認証有効期限日
            if (field_id === 301) {
                fields.push(project.certification_expiry_date_label);
                return;
            }
            // 競合
            if (field_id === 310) {
                fields.push(project.competitors_label);
                return;
            }
            // 他社落ち競合
            if (field_id === 311) {
                fields.push(project.lost_competitors_label);
                return;
            }
            // 送信元ページマスタ
            if (field_id === 430) {
                fields.push(project[key].detailed_channel_name);
                return;
            }
            // 失注先
            if (field_id === 480) {
                fields.push(project[key].competitor_name);
                return;
            }
            // ステータス
            if (field_id === 50) {
                fields.push(project.status_label);
                return;
            }
            // 評価
            if (field_id === 70) {
                fields.push(project.rank_label);
                return;
            }
            // 計測カウント
            if (field_id === 100) {
                fields.push(project.count_type_label);
                return;
            }
            // リスケ
            if (field_id === 140) {
                fields.push(project.is_rescheduling_label);
                return;
            }
            // 取得理由
            if (field_id === 330) {
                fields.push(project.purpose_label);
                return;
            }
            // 規格
            if (field_id === 340) {
                fields.push(project.standards_label_all);
                return;
            }
            // ビデオ通話
            if (field_id === 380) {
                fields.push(project.can_video_meeting_label);
                return;
            }
            // メールマガジン購読
            if (field_id === 420) {
                fields.push(project.subscribe_mail_magazine_label);
                return;
            }
            // 他社落ち理由
            if (field_id === 481) {
                fields.push(project.lost_reason_label);
                return;
            }

            // 案件連絡先トラン（客先担当者）
            if (field_id >= 600 && field_id < 700) {
                if (project.primary_contact === null) {
                    fields.push('');
                    return;
                }

                fields.push(project.primary_contact[key]);
                return;
            }

            // 顧客マスタ
            if (field_id >= 700 && field_id <= 910) {
                // 業種
                if (field_id === 730) {
                    fields.push(project.customer.industry_label);
                    return;
                }
                // 大手企業
                if (field_id === 740) {
                    fields.push(project.customer.is_major_label);
                    return;
                }
                // 会社規模
                if (field_id === 750) {
                    fields.push(project.customer.company_scale_label);
                    return;
                }
                // 従業員数
                if (field_id === 760) {
                    fields.push(project.customer.employee_number_label);
                    return;
                }
                // 決算期
                if (field_id === 800) {
                    fields.push(project.customer.fiscal_month_label);
                    return;
                }
                // 検索結果
                if (
                    field_id === 810 ||
                    field_id === 830 ||
                    field_id === 850 ||
                    field_id === 870
                ) {
                    fields.push(IsProblem.get(project.customer[key]));
                    return;
                }
                // 取引可否
                if (field_id === 890) {
                    fields.push(project.customer.is_tradable_label);
                    return;
                }
                // 電子契約
                if (field_id === 900) {
                    fields.push(project.customer.can_electronic_contract_label);
                    return;
                }

                fields.push(this.displayText(project.customer[key]));
                return;
            }

            // その他
            fields.push(this.displayText(project[key]));
            return;
        });

        return fields;
    }

    static displayText(text) {
        if(text && text.length > this.TEXT_LENGTH){
            return text.substring(0, this.TEXT_LENGTH) + '...';
        }
        return text;
    }

    static fieldIdsToObject(ids) {
        return ids.map(id => this.find(id));
    }

    static fieldIdsToObjectExcept(ids) {
        return this.all().filter(obj => !ids.includes(obj.id));
    }
}

export default DisplayFieldService;