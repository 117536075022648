import Enum from './enum'

/**
 * 案件一覧 検索リスト
 */
class IsUpdate extends Enum {
    static YES = 1; // 上書き
    static NO = 0; // 新規追加

    static values() {
        return {
            [this.YES]: '上書き',
            [this.NO]: '新規追加',
        }
    }
}

export default IsUpdate;
