import Enum from './enum'

/**
 * 個人用/共通用
 */
class ConditionType extends Enum {
    static PRIVATE = 1; // 個人用
    static COMMON = 2; // 共通用

    static values() {
        return {
            [this.PRIVATE]: '個人用',
            [this.COMMON]: '共通用',
        }
    }
}

export default ConditionType;
