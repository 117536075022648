/**
 * リストマスタ
 */
import { isBlank } from '@/utilities/typing';
import User from '@/models/entities/user';
import ConditionType from '@/models/enums/condition-type';
import Operator from '@/models/enums/operator';
import StatusOperator from '@/models/enums/status-operator';
import IsDateUnset from '@/models/enums/is-date-unset';
import ProjectOrderBy from '@/models/enums/project-order-by';

class Condition {
    condition_id;
    condition_type;
    user;
    condition_name;
    priority;
    keyword;
    department_operator;
    department_in;
    contact_user_id;
    follow_user_id;
    sales_user_id;
    area_id;
    status_operator;
    status_in;
    certainty_operator;
    certainty_in;
    probability_operator;
    probability_in;
    rank_operator;
    rank_in;
    detailed_rank_operator;
    detailed_rank_in;
    standard_in;
    next_approaching_date_is_null;
    next_approaching_date_from;
    next_approaching_date_to;
    contact_date_is_null;
    contact_date_from;
    contact_date_to;
    first_appointment_datetime_is_null;
    first_appointment_datetime_from;
    first_appointment_datetime_to;
    latest_appointment_datetime_is_null;
    latest_appointment_datetime_from;
    latest_appointment_datetime_to;
    first_latest_appointment_datetime_is_null;
    first_latest_appointment_datetime_from;
    first_latest_appointment_datetime_to;
    first_making_appointment_date_is_null;
    first_making_appointment_date_from;
    first_making_appointment_date_to;
    latest_making_appointment_date_is_null;
    latest_making_appointment_date_from;
    latest_making_appointment_date_to;
    certification_expiry_date_is_null;
    certification_expiry_date_from;
    certification_expiry_date_to;
    channel_operator;
    channel_in;
    temperature;
    exclude_no_count;
    appointment_source_operator;
    appointment_source_in;
    contract_status;
    campaign_number;
    display_fields;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.condition_id = properties.condition_id;
        this.condition_type = properties.condition_type;
        this.user = new User(properties.user);
        this.condition_name = properties.condition_name;
        this.priority = properties.priority ?? ProjectOrderBy.NEXT_APPROACHING_DATE_ASC;
        this.keyword = properties.keyword;
        this.department_operator = properties.department_operator ?? StatusOperator.CONTAIN;
        this.department_in = properties.department_in;
        this.contact_user_id = properties.contact_user_id;
        this.follow_user_id = properties.follow_user_id;
        this.sales_user_id = properties.sales_user_id;
        this.area_id = properties.area_id;
        this.status_operator = properties.status_operator ?? StatusOperator.CONTAIN;
        this.status_in = properties.status_in;
        this.certainty_operator = properties.certainty_operator ?? Operator.CONTAIN;
        this.certainty_in = properties.certainty_in;
        this.probability_operator = properties.probability_operator ?? Operator.CONTAIN;
        this.probability_in = properties.probability_in;
        this.rank_operator = properties.rank_operator ?? Operator.CONTAIN;
        this.rank_in = properties.rank_in;
        this.detailed_rank_operator = properties.detailed_rank_operator ?? Operator.CONTAIN;
        this.detailed_rank_in = properties.detailed_rank_in;
        this.standard_in = properties.standard_in;
        this.next_approaching_date_is_null = properties.next_approaching_date_is_null ?? IsDateUnset.NO;
        this.next_approaching_date_from = properties.next_approaching_date_from;
        this.next_approaching_date_to = properties.next_approaching_date_to;
        this.contact_date_is_null = properties.contact_date_is_null ?? IsDateUnset.NO;
        this.contact_date_from = properties.contact_date_from;
        this.contact_date_to = properties.contact_date_to;
        this.first_appointment_datetime_is_null = properties.first_appointment_datetime_is_null ?? IsDateUnset.NO;
        this.first_appointment_datetime_from = properties.first_appointment_datetime_from;
        this.first_appointment_datetime_to = properties.first_appointment_datetime_to;
        this.latest_appointment_datetime_is_null = properties.latest_appointment_datetime_is_null ?? IsDateUnset.NO;
        this.latest_appointment_datetime_from = properties.latest_appointment_datetime_from;
        this.latest_appointment_datetime_to = properties.latest_appointment_datetime_to;
        this.first_latest_appointment_datetime_is_null = properties.first_latest_appointment_datetime_is_null ?? IsDateUnset.NO;
        this.first_latest_appointment_datetime_from = properties.first_latest_appointment_datetime_from;
        this.first_latest_appointment_datetime_to = properties.first_latest_appointment_datetime_to;
        this.first_making_appointment_date_is_null = properties.first_making_appointment_date_is_null ?? IsDateUnset.NO;
        this.first_making_appointment_date_from = properties.first_making_appointment_date_from;
        this.first_making_appointment_date_to = properties.first_making_appointment_date_to;
        this.latest_making_appointment_date_is_null = properties.latest_making_appointment_date_is_null ?? IsDateUnset.NO;
        this.latest_making_appointment_date_from = properties.latest_making_appointment_date_from;
        this.latest_making_appointment_date_to = properties.latest_making_appointment_date_to;
        this.certification_expiry_date_is_null = properties.certification_expiry_date_is_null ?? IsDateUnset.NO;
        this.certification_expiry_date_from = properties.certification_expiry_date_from;
        this.certification_expiry_date_to = properties.certification_expiry_date_to;
        this.channel_operator = properties.channel_operator ?? Operator.CONTAIN;
        this.channel_in = properties.channel_in;
        this.temperature = properties.temperature;
        this.exclude_no_count = properties.exclude_no_count;
        this.appointment_source_operator = properties.appointment_source_operator ?? Operator.CONTAIN;
        this.appointment_source_in = properties.appointment_source_in;
        this.contract_status = properties.contract_status;
        this.campaign_number = properties.campaign_number;
        this.display_fields = properties.display_fields;
    }

    /**
     * getter
     */
    // 個人用/共通用 表示
    get condition_type_label() {
        return ConditionType.get(this.condition_type);
    }
}

export default Condition;
