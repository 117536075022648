import Enum from './enum'

/**
 *  案件一覧 日付検索条件
 */
class IsDateUnset extends Enum {
    static YES = 1; // 未設定
    static NO = 0; // 設定

    static values() {
        return {
            [this.YES]: '未設定',
            [this.NO]: '設定',
        }
    }
}

export default IsDateUnset;
