import Enum from './enum'

/**
 * シートモード更新フラグ
 */
class IsSheetMode extends Enum {
    static YES = 1;
    static NO = 0;
}

export default IsSheetMode;
